<template>
  <div class="workbench">
    <div class="hander_car">
      <div class="left_content">
        <img v-if="headerInfoObj.coverImage" :src="headerInfoObj.coverImage" alt="" />
        <img v-else src="@/assets/image/Group 2821.png" alt="" />
        <div class="info">
          <div class="standing">{{ headerInfoObj.partnerName }}</div>
          <div>
            <div class="partners_number">
              您一共发展<span>{{ headerInfoObj.partnerNumber || 0 }}</span
              >家合作伙伴
            </div>
            <div class="update_time" v-if="headerInfoObj.updateTime">
              最新更新时间：<span class="time">{{ headerInfoObj.updateTime }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="right_content">
        <!-- <div class="card">
          <div class="title">预获得收益共</div>
          <div class="amount">￥5000<span>元</span></div>
          <div class="statistics">关联订单（份）{{ headerInfoObj.orderNumber }}</div>
        </div> -->
        <div class="card">
          <div class="title">报备成功企业共</div>
          <div class="amount">{{ headerInfoObj.companySuccessNumber || 0 }}<span>家</span></div>
          <div class="statistics">报备中企业（家）{{ headerInfoObj.companyUnderNumber || 0 }}</div>
        </div>
      </div>
    </div>
    <div class="list">
      <div class="item" v-for="item in tableData" :key="item.id">
        <div class="linear">
          <img src="@/assets/image/Group 2821.png" alt="" />
          <div class="">{{ item.partnerName }}</div>
        </div>
        <div class="info">
          <span class="title">所在地区</span> {{ item.province }}•{{ item.city }}
        </div>
        <div class="info"><span class="title">发展时间</span> {{ item.createTime }}</div>
        <div class="btn_box">
          <div class="btn orange">关联订单{{ item.orderNumber || 0 }}</div>
          <div class="btn">报备企业{{ item.companyNumber || 0 }}</div>
        </div>
      </div>
      <div class="item" v-for="item in 5 - (tableData.length % 5)" :key="item.id"></div>
    </div>
    <div class="Pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.size"
        :page-sizes="[10, 30, 50]"
        :page-size="query.pageNum"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { listById, partnerGinsengListPage } from '@/api/cityPartner.js'
export default {
  name: 'CityPartner',
  data() {
    return {
      headerInfoObj: {}, //头部信息对象
      // 表格发送数据
      query: {
        cityPartnerId: '',
        partnerId: '',
        pageNum: 1,
        pageSize: 10
      },
      // 表格数据
      tableData: [],
      loading: false,
      // 分页总条数
      total: 0
    }
  },
  computed: {
    partnerId() {
      return JSON.parse(sessionStorage.getItem('typeMode'))?.id
    },
    cityPartnerId() {
      return JSON.parse(sessionStorage.getItem('typeMode'))?.cityPartnerId
    }
  },
  created() {
    this.search()
    this.getHeaderInfo()
  },

  methods: {
    async getHeaderInfo() {
      const { data: res } = await listById({ partnerId: this.partnerId })
      if (res.resultCode === 200) {
        this.headerInfoObj = res.data
      } else {
        this.$message.error(res.message)
      }
    },

    async search() {
      this.query.partnerId = this.partnerId
      this.query.cityPartnerId = this.cityPartnerId
      this.loading = true
      const { data: res } = await partnerGinsengListPage(this.query)
      if (res.resultCode === 200) {
        this.tableData = res.data.list
        this.total = res.data.total
        this.loading = false
      } else {
        this.$message.error(res.message)
      }
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.search()
    },
    handleCurrentChange(val) {
      this.query.pageNum = val
      this.search()
    }
  }
}
</script>

<style lang="less" scoped>
.hander_car {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 26px 20px;
  background: rgba(255, 255, 255, 1);

  border-radius: 8px;

  .left_content {
    display: flex;
    img {
      width: 98px;
      height: 98px;
      border-radius: 50%;
      margin: 0 20px;
      flex-shrink: 0;
    }
    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .standing {
        color: #151515;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 600;
      }
      .partners_number {
        color: #393939;
        font-size: 16px;
        font-family: PingFang SC;
        span {
          color: rgba(78, 147, 251, 1);
        }
      }
      .update_time {
        margin-top: 12px;

        color: #595959;
        font-size: 14px;
        font-family: PingFang SC;
        color: rgba(89, 89, 89, 1);
        .time {
          color: #333;
        }
      }
    }
  }
  .right_content {
    .card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-width: 195px;
      min-height: 122px;
      flex-shrink: 0;
      border-radius: 8px;
      border: 0.5px solid var(--unnamed, #d4d4d4);
      background: var(--unnamed, #fff);
      padding: 11px;
      box-sizing: border-box;
      margin-left: 20px;
      .title {
        color: rgba(9, 18, 31, 1);
        font-size: 14px;
        font-family: PingFang SC;
      }
      .amount {
        color: rgba(9, 18, 31, 1);
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: 600;
        span {
          font-size: 10px;
          margin-left: 5px;
        }
      }
      .statistics {
        color: #999;
        font-size: 12px;
        font-family: PingFang SC;
      }
    }
  }
}
.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .item {
    width: 324px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px;
    margin-top: 20px;
    background: white;
    align-self: self-start;
    .linear {
      display: flex;
      align-items: center;
      padding: 25px 15px 20px 15px;
      img {
        width: 29.47938346862793px;
        height: 19px;
        flex-shrink: 0;
        margin-right: 10px;
      }
      font-family: PingFang SC;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(0, 0, 0, 1);
      background: linear-gradient(rgba(#faebc7, 1), rgba(#fffbed, 1) 50%, rgba(#fffdf7, 1));
    }
    .info {
      margin: 0 15px;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(51, 51, 51, 1);
      margin-bottom: 10px;
      .title {
        color: rgba(208, 208, 208, 1);
        margin-right: 10px;
      }
    }
    .btn_box {
      margin: 0 15px;
      padding: 10px 0;
      border-top: 1px dashed rgba(212, 212, 212, 1);
      display: flex;

      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        // width: 94px;
        padding: 0 15px;
        height: 32px;
        border-radius: 46px;
        border: 1px solid;
        border-color: rgba(255, 125, 24, 1);
        font-family: PingFang SC;
        font-size: 11px;
        color: rgba(255, 125, 24, 1);
        font-weight: 400;
        cursor: pointer;
      }
      .orange {
        background: #ff7d18;
        color: white;
        margin-right: 10px;
      }
    }
  }
}
.Pagination {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 50px 0 50px 0;
}
</style>
